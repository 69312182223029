import type { bannerData } from '@/composables/banners/types/bannerTypes';
import { useBanners } from '@/composables/banners/useBanners';

export const useBannersStore = defineStore('banners-store', {
	state: () => ({
		banners_home: [] as bannerData[],
	}),
	actions: {
		async getBannersHome() {
			const { getBanners } = useBanners()
			const res = await getBanners({ isHome: 1, isActive: 1 })
			this.banners_home = res?.banners.data ?? []
		},
	}
})