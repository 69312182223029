<template>
  <div class="container">
    <div class="banner-container">
      <div class="swiper-controls">
        <div id="page_banner-prev" class="arrow-container">
          <img src="assets/images/svg/arrow-left.svg" alt="">
        </div>
        <div id="page_banner-pagination" class="swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
          <span v-for="(n, i) in banners" :key="i" class="swiper-pagination-bullet" :class="{ 'swiper-pagination-bullet-active': i === 0 }"></span>
        </div>
        <div id="page_banner-next" class="arrow-container">
          <img src="assets/images/svg/arrow-right.svg" alt="">
        </div>
      </div>
      <div class="swiper-content">
        <Swiper :modules="[Navigation, Pagination, Mousewheel, Autoplay]" :autoplay="{ delay: 5000 }" :mousewheel="{ forceToAxis: true }" :pagination="{ el: '#page_banner-pagination', clickable: true }" :navigation="{
          nextEl: '#page_banner-next',
          prevEl: '#page_banner-prev',
        }" class='slider'>
          <SwiperSlide v-for="(slide, i) in banners" :key="i" class='slide'>
            <div class='slide-content'>
              <picture>
                <nuxt-source :preload="i === 0" media="(min-width: 768px)" :src="slide?.image_id" sizes="1008px lg:1320px" />
                <nuxt-source :preload="i === 0" media="(max-width: 767px)" v-if="slide?.image_mobile_id" :src="slide?.image_mobile_id" sizes="624px sm:752px" />
                <NuxtImgC width="100%" height="263px" :src="slide?.image_id" format="png" alt="" sizes="1008px lg:1320px" />
              </picture>
              <p v-if="slide.title" class="title">{{ slide.title }}</p>
              <p v-if="slide.message" class="message">{{ slide.message }}</p>
              <nuxt-link
                v-if="slide.url && slide.url.length > 0"
                :to="slide.url"
                class="link-absolute"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/css";
import type { bannerData } from '~/composables/banners/types/bannerTypes';
const { locale } = useI18n()
defineProps<{
  banners: bannerData[]
}>()
</script>

<style lang="scss" scoped>
.banner-container {
  margin-top: 19px;
  position: relative;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ffffff45;
  border-radius: 50%;
  cursor: pointer;

  img {
    filter: invert(1);
  }
}

.arrow-container:hover {
  background-color: var(--white);

  img {
    filter: invert(7%) sepia(15%) saturate(6763%) hue-rotate(226deg) brightness(107%) contrast(111%);
  }
}

.swiper-content {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  transform: translateZ(0);
}

.slide {
  height: auto
}

.slide-content {
  min-height: 263px;
  padding: 39px 35px 69px;

  .slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .title {
    font-weight: 800;
    font-size: 44px;
    line-height: 92%;
    letter-spacing: -0.03em;
    color: #fff;
    margin-bottom: 12px;
    max-width: 510px;
  }

  .message {
    font-weight: 600;
    font-size: 18px;
    line-height: 115%;
    letter-spacing: -0.03em;
    color: #fff;
    opacity: 0.7;
    max-width: 520px;
  }

  img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.swiper-controls {
  position: absolute;
  display: flex;
  gap: 10px;
  z-index: 2;
  left: 35px;
  bottom: 23px;

  .swiper-pagination-bullets {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .swiper-pagination-clickable {
    :deep(.swiper-pagination-bullet) {
      cursor: pointer;
    }
  }

  :deep(.swiper-pagination-bullet) {
    height: 10px;
    width: 10px;
    background-color: var(--white);
    opacity: 30%;
    border-radius: 50%;

    &:hover {
      opacity: 1;
    }
  }

  :deep(.swiper-pagination-bullet-active) {
    opacity: 1;
  }
}

@media (max-width:1023px) {
  .banner-container {
    margin-top: 14px;
  }

  .swiper-content {
    border-radius: 16px;
  }

  .slide-content {
    min-height: 296px;
    padding: 16px 20px 48px;

    .title {
      font-size: 26px;
      margin-bottom: 6px;
    }

    .message {
      font-size: 15px;
    }
  }

  .swiper-controls {
    left: 20px;
    bottom: 20px;
  }
}
</style>