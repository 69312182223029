
import type { bannerRequestType, bannerResponse } from './types/bannerTypes';

export function useBanners() {
	const { $api } = useNuxtApp();

	const getBanners = async (options?: bannerRequestType) => {
		const res = await $api<bannerResponse>('/banner/all', {
			method: 'GET',
			params: {
				...options
			}
		});

		if (res) {
			return res;
		}
		return null;
	}

	return {
		getBanners
	}
}
