<template>
  <div class="home-page__popular-buttons-root">
    <div class="container">
      <div
        class="home-page__popular-buttons-list"
        :class="{ 'home-page__popular-buttons-list_once' : fakeItems.length < 2 }"
      >
        <div
            v-for="(button, index) in fakeItems"
            :key="`popular-button-${button.name}-${index}`"
            class="home-page__popular-button-item"
            :class="{ 'home-page__popular-button-item-once': fakeItems.length < 2 }"
        >
          <popular-button
            :item="button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PopularButton from '@/components/popular-button/PopularButton.vue'
// сюда добавим метод с бэка для отрисковки множества кнопок
const fakeItems = computed(() => {
  return [
    {
      name: 'steam',
      image_id: useAsset('/assets/images/svg/steam-3.svg'),
      url: '/steam-topup',
    },
    // {
    //   name: 'steam',
    //   image_id: useAsset('/assets/images/svg/steam-3.svg'),
    //   url: '/steam-topup',
    // },
    // {
    //   name: 'steam',
    //   image_id: useAsset('/assets/images/svg/steam-3.svg'),
    //   url: '/steam-topup',
    // },
  ]
})

</script>

<style scoped lang="scss">
.home-page__popular-buttons-root {
  padding-top: 10px;
}
.home-page__popular-buttons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &.home-page__popular-buttons-list_once {
    display: block;
  }
}
.home-page__popular-button-item-once {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
