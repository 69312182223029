<template>
    <div class="container">
        <div class="popular-products">
            <div class="produtcs-title">
                <h3>{{ $t('product.popular_products_title') }}</h3>
                <img src="assets/images/png/product/cup.webp" alt="" height="42px" width="42px">
            </div>
            <ProductSelection @cardBuyClick="reachGoal('button_buy_product_mini_card_top')" :productCards="products" :link="{ label: $t('product.all'), to: '/products' }" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import ProductSelection from '../product/ProductSelection.vue'
import type { productData } from '~/composables/products/types/productTypes';
const props = defineProps<{
    products: productData[]
}>()

const { reachGoal } = useYm()
</script>

<style lang="scss" scoped>
.produtcs-title {
    display: flex;
    gap: 12px 10px;
    justify-content: center;
    align-items: center;
    color: #1D254A;
    font-size: 40px;
    font-weight: 800;
    line-height: 80%;
    letter-spacing: -0.03em;
    img {
        width: 42px;
        height: 42px;
    }
}

.popular-products {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media (max-width:1023px) {
    .produtcs-title {
        flex-direction: column-reverse;
        font-size: 28px;

        img {
            width: 32px;
            height: 32px;
        }
    }
}
</style>
