<template>
  <div class="main-page-wrapper">
    <!-- <DashBoard /> -->
    <Banner :banners="banners_home" v-if="banners_home.length" />
    <PopularButtons />
    <div class="container services-container">
      <ServicesGages v-if="service_home?.totalSubCategories" type="services" :to="`${getCategoryId('services')}`" :name="$t('home.services')" :btnString="$t('home.allServices')" :servicesCount="service_home.totalSubCategories" :itemsArr="service_home.categories.data" :bgColor="'#DA47271A'" :btnBg="'#DA4727'" />
      <ServicesGages v-if="games_home?.totalSubCategories" type="games" :to="`${getCategoryId('games')}`" :name="$t('home.games')" :btnString="$t('home.allGames')" :servicesCount="games_home.totalSubCategories" :itemsArr="games_home.categories.data" :bgColor="'#0906301A'" :btnBg="'#090630'" />
    </div>
    <div class="pink-bg">
      <PopularProducts v-if="products_popular_home.length" :products="products_popular_home" />
      <PopularStores v-if="shopsHome.length" :stores="shopsHome" />
    </div>
    <PopularArticles v-if="data && data.data.length" :articles="data.data" />
  </div>
</template>

<script setup lang="ts">
import Banner from '../components/home/Banner.vue'
import PopularStores from '../components/home/PopularStores.vue'
import ServicesGages from '../components/home/ServicesGages.vue'
import PopularProducts from '../components/home/PopularProducts.vue'
import PopularArticles from '../components/home/PopularArticles.vue'
import PopularButtons from '../components/home/PopularButtons.vue'
import { useArticles } from '~/composables/articles/UseArticles'
import { useShops } from '~/composables/shops/UseShops'
import { useShopStore } from '~/stores/shopstore'
import { useproductstore } from '~/stores/products'
import { seoHandlers } from '~/utils'
import { useMeta } from '~/composables/meta/useMeta'
import { useCatalog } from '~/composables/catalog/useCatalog'
const { setSEOTitles} = seoHandlers()
const { getArticles } = useArticles()
const { data } = useAsyncData('mainpagearticles', () => getArticles({ limit: 4, page: 1, sort: 'created_at:desc', 'isActive': 1, 'isPopular': 1 }))

const productStore = useproductstore();
const { getPopularProductsHome, getWishIds } = productStore
const { products_popular_home } = storeToRefs(productStore)
const { getShops } = useShops()
const { getCategories } = useCatalog()
const store = useShopStore();
const { shopsHome } = storeToRefs(store)
const { getBannersHome } = useBannersStore()
const { banners_home } = storeToRefs(useBannersStore())
const categoriesStore = useCategoriesStore()
const { getGamesHome, getServicesHome, getCategoryId } = categoriesStore
const { games_home, service_home } = storeToRefs(categoriesStore)
const { locale } = useI18n();
const {getMetaByUrl} = useMeta();
const {data: meta} = await useAsyncData('mainpageMeta', () => getMetaByUrl('index'))
if (meta.value) {
  setSEOTitles({
    seoTitle: meta.value?.seo_meta_data?.seo_title || '',
    seoDescription: meta.value?.seo_meta_data?.seo_description || '',
    seoKeywords: meta.value?.seo_meta_data?.seo_keywords || ''
  })
}
getCategories()
getPopularProductsHome(),

await callOnce(async () => {
  await Promise.all([
    getBannersHome(),
    getServicesHome(),
    getGamesHome(),
    getPopularProductsHome(),
    getShops({ limit: 6, isHome: 1, page: 1, sort: 'id:asc', exclude: 'products' }, false, true),
  ])
})

// const MainPopularServices = await useAsyncData('mainStores', () => ge)

</script>

<style scoped lang="scss">
.main-page-wrapper {
  margin-top: 19px;
}

.services-container {
  display: flex;
  gap: 12px 16px;
  margin-top: 32px;
  margin-bottom: 86px;
}

.pink-bg {
  background: linear-gradient(#fff 0, #FBEEEE 62%, #FBEEEE 100%);
  border-radius: 0 0 64px 64px;
  padding-bottom: 56px;
}

@media (max-width:1023px) {
  .services-container {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .pink-bg {
    border-radius: 0 0 24px 24px;
    padding-bottom: 15px;
  }

  
}
</style>
