<template>
    <div class="container">
        <div class="popular-stores">
            <div class="title-container">
                <h3>{{ $t('stores.popular_stores_title') }}</h3>
                <img width="42px" height="42px" src="assets/images/png/stores/store-img.webp">
            </div>
            <div class="stores-container">
                <StoreCard v-for="store in props.stores" 
                :slug="store.slug"
                :key="store.created_at"
                :insuaranceBalance="store.insurance_balance"
                :salesPerLastMonth="store.recent_sales"
                :name="store.name" :rating="store.total_rating" :partnerSince="store.created_at" :storeImgId="store?.image_id" />
            </div>
            <NuxtLinkLocale to="/stores" class="btn btn-secondary">{{ $t('stores.all_stores') }}</NuxtLinkLocale>
        </div>
    </div>
</template>

<script lang="ts" setup>
import StoreCard from '../store/StoreCard.vue'
import type {ShopData} from '~/composables/shops/types/ShopTypes'
const { locale} = useI18n();
const props = defineProps<{
  stores: ShopData[]
}>()

</script>

<style lang="scss" scoped>
.popular-stores {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 86px;
}

.stores-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.title-container {
    display: flex;
    gap: 12px 10px;
    justify-content: center;
    align-items: center;
    color: #1D254A;
    font-size: 40px;
    font-weight: 800;
    line-height: 80%;
    letter-spacing: -0.03em;

    img {
        width: 42px;
        height: 42px;
    }
}

.btn {
    margin: auto;
    text-align: center;
    width: 303px;
    font-size: 22px;
    line-height: 80%;
    padding: 16px 20px 14px;
    letter-spacing: -0.03em;
}

@media (max-width:1023px) {

    .popular-stores {
        gap: 28px;
        margin-top: 64px;
    }

    .stores-container {
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
        gap: 12px 8px;
    }

    .title-container {
        flex-direction: column-reverse;
        font-size: 28px;

        img {
            width: 32px;
            height: 32px;
        }
    }

    .btn {
        margin-top: -13px;
        padding: 14px 20px 14px;
        font-size: 20px;
    }
}

@media (max-width:639px) {
    .btn {
        width: 100%;
    }
}
</style>
