import { useCategories } from '~/composables/categories/useCategories';
import type { SubCategoriesResponse } from '~/composables/categories/types/CategoriesTypes';
export const useCategoriesStore = defineStore('categories-store', {
	state: () => ({
		games_home: null as SubCategoriesResponse | null,
		service_home: null as SubCategoriesResponse | null,
	}),
	actions: {
		async getGamesHome() {
			const { getSubCategories } = useCategories()
			const res = await getSubCategories('games', { limit: 12, page: 1 })
			this.games_home = res
		},
		async getServicesHome() {
			const { getSubCategories } = useCategories()
			const res = await getSubCategories('services', { limit: 12, page: 1 })
			this.service_home = res
		},
		getCategoryId(name: string) {
			const { majorCategories } = storeToRefs(useCatalogStore())
			/** return majorCategories.value.find(a => a.name.en.toLowerCase() == name.toLowerCase())?.id */
			return majorCategories.value.find(a => a.name.toLowerCase() == name.toLowerCase())?.id
		}
	}
})