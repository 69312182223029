<template>
	<div class="gages-container" :style="{ backgroundColor: props.bgColor }">
		<div class="title-group">
			<span class="title">
        {{ props.name }}
        <sup v-if="false" class="count">
          {{ servicesCount }}
        </sup>
      </span>
			<NuxtLinkLocale :to="props.type" class="btn" :style="{ backgroundColor: props.btnBg }">{{ btnString }}</NuxtLinkLocale>
		</div>
		<div class="content">
			<NuxtLinkLocale v-for="service in props.itemsArr" :key="service.id" :to="service.slug" class="gage-item">
				<NuxtImgC :src="service?.image_id" :fallback="fallbackImg" width="34px" height="34px" sizes="34px" />
				<span
          v-if="service.name"
        >
          {{ service.name || '' }}
        </span>
			</NuxtLinkLocale>
		</div>
		<NuxtLinkLocale :to="props.type" class="btn" :style="{ backgroundColor: props.btnBg }">{{ btnString }}</NuxtLinkLocale>
	</div>
</template>

<script lang="ts" setup>
import type { SubCategoriesData } from '~/composables/categories/types/CategoriesTypes';
interface IProps {
	name: string;
	btnString: string;
	bgColor: string;
	btnBg: string;
	itemsArr: SubCategoriesData[];
	to: string | number
	type: 'games' | 'services',
	servicesCount: number
}

const { locale } = useI18n()
const props = defineProps<IProps>();
const fallbackImg = useAsset('/assets/images/png/default-imgs/product.png')
</script>

<style lang="scss" scoped>
div {
	box-sizing: border-box;
}

.gages-container {
	display: flex;
	flex-direction: column;
	padding: 30px;
	border-radius: 40px;
	flex-grow: 1;
}

.title-group {
	display: flex;
	margin-bottom: 24px;
	align-items: center;
	justify-content: space-between;

	.title {
		display: flex;
		gap: 6px;
		font-weight: 800;
		font-size: 28px;
		line-height: 80%;
		color: #1d254a;
	}

	.count {
		align-self: flex-start;
		font-weight: 800;
		font-size: 16px;
		line-height: 80%;
		color: #DA4727;
	}

	.btn {
		display: none;
	}
}

.content {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	gap: 8px;
	font-size: 15px;
	letter-spacing: -0.03em;
	margin-bottom: auto;
}

.gage-item {
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 6px;
	border-radius: 30px;
	background-color: white;
	box-shadow: 0px 2px 4px 0px #00000012;
	flex-grow: 1;
	color: #283647;
	transition: box-shadow .3s ease;

	&:hover {
		box-shadow: 0 4px 12px 0 rgba(33, 31, 53, 0.5);
	}

	.popo {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background-color: green;
	}

	img {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		object-fit: cover;
		flex-shrink: 0;
	}

	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
}

.btn {
	padding: 14px 20px 12px;
	display: block;
	color: white;
	cursor: pointer;
	width: 208px;
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	line-height: 80%;
	letter-spacing: -0.03em;
	margin-top: 20px;

	&:hover {
		opacity: 0.8;
	}

}

@media (max-width:1023px) {
	.gages-container {
		padding: 20px 12px 12px;
		border-radius: 24px;
	}

	.title-group {
		margin-bottom: 12px;

		.title {
			font-size: 24px;
		}

		.count {
			font-size: 14px;
		}

		.btn {
			display: flex;
			margin-top: 0;
		}
	}

	.btn {
		display: none;
		margin-top: 12px;
		padding: 12px 24px 10px;
		font-size: 16px;
		width: 147px;
	}

	.content {
		gap: 6px;
	}
}
</style>
