<template>
  <nuxt-link-locale
    :to="item.url"
    class="popular-button"
    :class="{ 'popular-button_hightlight': hightLight }"
  >
    <img
      v-if="item.image_id"
      :src="item.image_id"
      width="24"
      height="24"
      class="popular-button__image"
      alt=""
    />
    <span
      class="popular-button__text"
    >
      {{ $t(`popularButtons.${item.name}`) }}
    </span>
  </nuxt-link-locale>
</template>

<script setup lang="ts">
defineProps<{
  item: any // прописать тип, который будет приходить от бэка
}>()
const hightLight = ref<boolean>(false);

const autoToggleHightLight = function () {
  setInterval(() => {
    hightLight.value = true;
    repayHightLight();
  }, 15000);
}

const repayHightLight = function () {
  setTimeout(() => {
    hightLight.value = false;
  }, 5000)
}

onMounted(() => {
  autoToggleHightLight();
})
</script>

<style lang="scss">
.popular-button {
  background-color: rgba(6, 102, 171, 1);
  color: white;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 7px;
  width: 100%;
  justify-content: center;
  border-radius: 12px;
  min-width: fit-content;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: rgba(6, 102, 171, 0.8);
  }

  &.popular-button_hightlight {
    background-color: rgba(6, 102, 171, 0.95);
    &:hover {
      background-color: rgba(6, 102, 171, 0.8);
    }
  }
}
.popular-button__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}
</style>
