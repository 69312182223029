<template>
    <div class="selection-wrapper">
        <div class="cards">
            <template v-for="card in productCards">
                <ProductCard @buyClick="emits('cardBuyClick')" :card />
            </template>
        </div>
        <NuxtLinkLocale :to="link?.to" class="btn btn-primary">{{ link.label }}</NuxtLinkLocale>
    </div>
</template>
<script lang='ts' setup>
import type { productData } from '~/composables/products/types/productTypes';

const props = defineProps({
    productCards: {
        type: Array<productData>
    },
    link: { type: Object as PropType<{ to: string, label: string }>, default: { label: 'Все предложения', to: '/products' } }
})

const emits = defineEmits<{
	cardBuyClick:[]
}>()
</script>

<style lang="scss" scoped>
.selection-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.btn {
    margin: auto;
    width: 303px;
    text-align: center;
    font-size: 22px;
    line-height: 80%;
    padding: 16px 20px 14px;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
}

@media (max-width:1023px) {
    .selection-wrapper {
        gap: 15px;
    }

    .cards {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 12px 8px;
    }

}

@media (max-width:639px) {
    .cards {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .btn {
        margin: auto;
        width: 100%;
        text-align: center;
        font-size: 20px;
        padding: 14px 20px 14px;
    }
}
</style>
